export const ROUTES = {
    HOME: "/",
    PURCHASE: "/purchase",
    IMEI: "/purchase/imei",
    RETURN: "/purchase/return",
    ACCOUNT_SUMMARY: "/purchase/account-summary",
    DEVICE_INFO_UNSUCCESSFUL: "/purchase/device-info-unsuccessful",
    PLANS: "/purchase/plans",
    FAQ: "/purchase/frequently-asked-questions",
    ACCOUNT: "/purchase/account",
    REVIEW_ORDER: "/purchase/review-order",
    VERIFY_DEVICE_PHOTO: "/purchase/verify-device-photo",
    VERIFY_WEARABLES_PHOTO: "/purchase/verify-wearables-photo",
    REUPLOAD_DEVICE_PHOTO: "/purchase/reupload-device-photo",
    REUPLOAD_DEVICE_PHOTO_SUCCESS: "/purchase/reupload-device-photo-success",
    PAYMENT_INITIATE: "/purchase/payment-initiate",
    UPLOAD_SUCCESS: "/purchase/upload-success",
    ACTIVATION_SUCCESS: "/purchase/activation-success",
    ACTIVATION_UNSUCCESSFUL: "/purchase/activation-unsuccessful",
    CONTRACT_PREPARATION: "/purchase/contract-preparation",
    CONTRACT_PREPARATION_SUCCESS: "/purchase/contract-preparation-success",
    CUSTOMER_COLLECTION:"/purchase/customer-collection",
    CUSTOMER_COLLECTION_SUCCESS:"/purchase/customer-collection-success",
    TNC01: "/purchase/terms-and-conditions/01",
    TNC02: "/purchase/terms-and-conditions/02",
    TNC03: "/purchase/terms-and-conditions/03",
    TNC04: "/purchase/terms-and-conditions/04",
    TNC05: "/purchase/terms-and-conditions/05",
    TNC06: "/purchase/terms-and-conditions/06",
    TNC07: "/purchase/terms-and-conditions/07",
    TNC08_PREMIUM: "/purchase/terms-and-conditions/08-premium",
    TNC08_LITE: "/purchase/terms-and-conditions/08-lite",
    TNC_REPORT: "/purchase/terms-and-conditions/report",
    SYSTEM_ERROR: "/purchase/system-error",
    QR_SCAN: "/purchase/qr-scan",
    MAINTENANCE: "/maintenance",
    PAGE_NOT_FOUND: "/page-not-found",
};

export const LANGUAGE_NAME = {
    en: "EN",
    zh: "繁體",
};

export const ENDPOINT_PATH = {
    RETURN_SIGNIN: "/partnerOrder/authenticatePartnerOrder",
    REQUEST_OTP: "/otp/create",
    VALIDATE_OTP: "/otp/confirm",
    VALIDATE_DEVICE: "/partnerOrder/validateDevice",
    INITIATE_ORDER: "/partnerOrder/initiateOrder",
    UPLOAD_DEVICE_IMAGE: "/partnerOrder/uploadOrderFile",
    COMPLETE_ORDER: "/partnerOrder/completeOrder",
    CONTRACT_VALIDATION: "/contract/validate",
    CONTRACT_SIGN: "/contract/sign",
    CONTRACT: "/contract",
    CONTRACT_DEVICES: "/contract/devices",
    CONTRACT_DEVICE_PLAN_PRICE: "/contract/device/planPrice"
};

export const CONTACT_NUMBER = "0809-010-151";

export const BASIS_CODE_INITIAL = "SM-";

export const DATE_FORMAT = "YYYY/MM/DD";

export const TIMESTAMP_API_MOMENT_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZZ";

export const AREA_CODE = [
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "037", value: "037" },
    { label: "038", value: "038" },
    { label: "04", value: "04" },
    { label: "049", value: "049" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "082", value: "082" },
    { label: "0826", value: "0826" },
    { label: "0836", value: "0836" },
    { label: "089", value: "089" },
];

export const errorKeyToNavigateSystemErrorPage = [
    { key: "partnerService.getPartnerAdapter.partnerAdapterInitializationError" },
    { key: "partnerService.validateDevice.planNotFound" },
    { key: "partner.getPackage.failed" },
    { key: "partner.getPackage.noPackageFound" },
    { key: "partner.authenticate.failed" },
    { key: "partner.getPremiumAndRate.failed" },
    { key: "partner.getPremiumAndRate.empty" },
    { key: "partner.checkMobilePolicy.failed" },
    { key: "partner.parsingPropertyFailed" },
    { key: "partner.initiateOrder.failed" },
    { key: "payment.preAuthorize.failed" },
    { key: "partner.confirmOrder.failed" },
    { key: "CRMZ.submit.failedToCreateOrder" },
    { key: "CRMZ.submit.httpStatusNotOk" },
    { key: "CRMZ.getToken.httpStatusNotOk" },
    { key: "CRMZ.getToken.notSuccessStatus" },
    { key: "partner.getPackage.failed" },
    { key: "samsungCarePlusService.validateDevice.httpStatusNotOk" },
    { key: "smsService.send.smsAdapterError" },
    { key: "otpService.create.invalidChannel" },
];

export const POSTAL_AREA_BY_CITY = [
    {
        city: "基隆市",
        areas: [
            { area: "仁愛區", postalCode: "200" },
            { area: "信義區", postalCode: "201" },
            { area: "中正區", postalCode: "202" },
            { area: "中山區", postalCode: "203" },
            { area: "安樂區", postalCode: "204" },
            { area: "暖暖區", postalCode: "205" },
            { area: "七堵區", postalCode: "206" },
        ],
    },
    {
        city: "台北市",
        areas: [
            { area: "中正區", postalCode: "100" },
            { area: "大同區", postalCode: "103" },
            { area: "中山區", postalCode: "104" },
            { area: "松山區", postalCode: "105" },
            { area: "大安區", postalCode: "106" },
            { area: "萬華區", postalCode: "108" },
            { area: "信義區", postalCode: "110" },
            { area: "士林區", postalCode: "111" },
            { area: "北投區", postalCode: "112" },
            { area: "內湖區", postalCode: "114" },
            { area: "南港區", postalCode: "115" },
            { area: "文山區", postalCode: "116" },
        ],
    },
    {
        city: "新北市",
        areas: [
            { area: "萬里區", postalCode: "207" },
            { area: "金山區", postalCode: "208" },
            { area: "板橋區", postalCode: "220" },
            { area: "汐止區", postalCode: "221" },
            { area: "深坑區", postalCode: "222" },
            { area: "石碇區", postalCode: "223" },
            { area: "瑞芳區", postalCode: "224" },
            { area: "平溪區", postalCode: "226" },
            { area: "雙溪區", postalCode: "227" },
            { area: "貢寮區", postalCode: "228" },
            { area: "新店區", postalCode: "231" },
            { area: "坪林區", postalCode: "232" },
            { area: "烏來區", postalCode: "233" },
            { area: "永和區", postalCode: "234" },
            { area: "中和區", postalCode: "235" },
            { area: "土城區", postalCode: "236" },
            { area: "三峽區", postalCode: "237" },
            { area: "樹林區", postalCode: "238" },
            { area: "鶯歌區", postalCode: "239" },
            { area: "三重區", postalCode: "241" },
            { area: "新莊區", postalCode: "242" },
            { area: "泰山區", postalCode: "243" },
            { area: "林口區", postalCode: "244" },
            { area: "蘆洲區", postalCode: "247" },
            { area: "五股區", postalCode: "248" },
            { area: "新北產業園區", postalCode: "248" },
            { area: "八里區", postalCode: "249" },
            { area: "淡水區", postalCode: "251" },
            { area: "三芝區", postalCode: "252" },
            { area: "石門區", postalCode: "253" },
        ],
    },
    {
        city: "桃園市",
        areas: [
            { area: "中壢區", postalCode: "320" },
            { area: "平鎮區", postalCode: "324" },
            { area: "龍潭區", postalCode: "325" },
            { area: "楊梅區", postalCode: "326" },
            { area: "新屋區", postalCode: "327" },
            { area: "觀音區", postalCode: "328" },
            { area: "桃園區", postalCode: "330" },
            { area: "龜山區", postalCode: "333" },
            { area: "八德區", postalCode: "334" },
            { area: "大溪區", postalCode: "335" },
            { area: "復興區", postalCode: "336" },
            { area: "大園區", postalCode: "337" },
            { area: "蘆竹區", postalCode: "338" },
        ],
    },
    {
        city: "新竹市",
        areas: [
            { area: "北區", postalCode: "300" },
            { area: "東區", postalCode: "300" },
            { area: "香山區", postalCode: "300" },
        ],
    },
    {
        city: "新竹縣",
        areas: [
            { area: "竹北市", postalCode: "302" },
            { area: "湖口鄉", postalCode: "303" },
            { area: "新豐鄉", postalCode: "304" },
            { area: "新埔鎮", postalCode: "305" },
            { area: "關西鎮", postalCode: "306" },
            { area: "芎林鄉", postalCode: "307" },
            { area: "寶山鄉", postalCode: "308" },
            { area: "竹東鎮", postalCode: "310" },
            { area: "五峰鄉", postalCode: "311" },
            { area: "橫山鄉", postalCode: "312" },
            { area: "尖石鄉", postalCode: "313" },
            { area: "北埔鄉", postalCode: "314" },
            { area: "峨眉鄉", postalCode: "315" },
        ],
    },
    {
        city: "花蓮縣",
        areas: [
            { area: "花蓮市", postalCode: "970" },
            { area: "新城鄉", postalCode: "971" },
            { area: "秀林鄉", postalCode: "972" },
            { area: "吉安鄉", postalCode: "973" },
            { area: "壽豐鄉", postalCode: "974" },
            { area: "鳳林鎮", postalCode: "975" },
            { area: "光復鄉", postalCode: "976" },
            { area: "豐濱鄉", postalCode: "977" },
            { area: "瑞穗鄉", postalCode: "978" },
            { area: "萬榮鄉", postalCode: "979" },
            { area: "玉里鎮", postalCode: "981" },
            { area: "卓溪鄉", postalCode: "982" },
            { area: "富里鄉", postalCode: "983" },
        ],
    },
    {
        city: "宜蘭縣",
        areas: [
            { area: "宜蘭市", postalCode: "260" },
            { area: "頭城鎮", postalCode: "261" },
            { area: "礁溪鄉", postalCode: "262" },
            { area: "壯圍鄉", postalCode: "263" },
            { area: "員山鄉", postalCode: "264" },
            { area: "羅東鎮", postalCode: "265" },
            { area: "三星鄉", postalCode: "266" },
            { area: "大同鄉", postalCode: "267" },
            { area: "五結鄉", postalCode: "268" },
            { area: "冬山鄉", postalCode: "269" },
            { area: "蘇澳鎮", postalCode: "270" },
            { area: "南澳鄉", postalCode: "272" },
            { area: "釣魚臺", postalCode: "290" },
        ],
    },
    {
        city: "苗栗縣",
        areas: [
            { area: "竹南鎮", postalCode: "350" },
            { area: "頭份市", postalCode: "351" },
            { area: "三灣鄉", postalCode: "352" },
            { area: "南庄鄉", postalCode: "353" },
            { area: "獅潭鄉", postalCode: "354" },
            { area: "後龍鎮", postalCode: "356" },
            { area: "通霄鎮", postalCode: "357" },
            { area: "苑裡鎮", postalCode: "358" },
            { area: "苗栗市", postalCode: "360" },
            { area: "造橋鄉", postalCode: "361" },
            { area: "頭屋鄉", postalCode: "362" },
            { area: "公館鄉", postalCode: "363" },
            { area: "大湖鄉", postalCode: "364" },
            { area: "泰安鄉", postalCode: "365" },
            { area: "銅鑼鄉", postalCode: "366" },
            { area: "三義鄉", postalCode: "367" },
            { area: "西湖鄉", postalCode: "368" },
            { area: "卓蘭鎮", postalCode: "369" },
        ],
    },
    {
        city: "台中市",
        areas: [
            { area: "中區", postalCode: "400" },
            { area: "東區", postalCode: "401" },
            { area: "南區", postalCode: "402" },
            { area: "西區", postalCode: "403" },
            { area: "北區", postalCode: "404" },
            { area: "北屯區", postalCode: "406" },
            { area: "西屯區", postalCode: "407" },
            { area: "南屯區", postalCode: "408" },
            { area: "太平區", postalCode: "411" },
            { area: "大里區", postalCode: "412" },
            { area: "霧峰區", postalCode: "413" },
            { area: "烏日區", postalCode: "414" },
            { area: "豐原區", postalCode: "420" },
            { area: "后里區", postalCode: "421" },
            { area: "石岡區", postalCode: "422" },
            { area: "東勢區", postalCode: "423" },
            { area: "和平區", postalCode: "424" },
            { area: "新社區", postalCode: "426" },
            { area: "潭子區", postalCode: "427" },
            { area: "大雅區", postalCode: "428" },
            { area: "神岡區", postalCode: "429" },
            { area: "大肚區", postalCode: "432" },
            { area: "沙鹿區", postalCode: "433" },
            { area: "龍井區", postalCode: "434" },
            { area: "梧棲區", postalCode: "435" },
            { area: "清水區", postalCode: "436" },
            { area: "大甲區", postalCode: "437" },
            { area: "外埔區", postalCode: "438" },
            { area: "大安區", postalCode: "439" },
        ],
    },
    {
        city: "彰化縣",
        areas: [
            { area: "彰化市", postalCode: "500" },
            { area: "芬園鄉", postalCode: "502" },
            { area: "花壇鄉", postalCode: "503" },
            { area: "秀水鄉", postalCode: "504" },
            { area: "鹿港鎮", postalCode: "505" },
            { area: "福興鄉", postalCode: "506" },
            { area: "線西鄉", postalCode: "507" },
            { area: "和美鎮", postalCode: "508" },
            { area: "伸港鄉", postalCode: "509" },
            { area: "員林市", postalCode: "510" },
            { area: "社頭鄉", postalCode: "511" },
            { area: "永靖鄉", postalCode: "512" },
            { area: "埔心鄉", postalCode: "513" },
            { area: "溪湖鎮", postalCode: "514" },
            { area: "大村鄉", postalCode: "515" },
            { area: "埔鹽鄉", postalCode: "516" },
            { area: "田中鎮", postalCode: "520" },
            { area: "北斗鎮", postalCode: "521" },
            { area: "田尾鄉", postalCode: "522" },
            { area: "埤頭鄉", postalCode: "523" },
            { area: "溪州鄉", postalCode: "524" },
            { area: "竹塘鄉", postalCode: "525" },
            { area: "二林鎮", postalCode: "526" },
            { area: "大城鄉", postalCode: "527" },
            { area: "芳苑鄉", postalCode: "528" },
            { area: "二水鄉", postalCode: "530" },
        ],
    },
    {
        city: "南投縣",
        areas: [
            { area: "南投市", postalCode: "540" },
            { area: "中寮鄉", postalCode: "541" },
            { area: "草屯鎮", postalCode: "542" },
            { area: "國姓鄉", postalCode: "544" },
            { area: "埔里鎮", postalCode: "545" },
            { area: "仁愛鄉", postalCode: "546" },
            { area: "名間鄉", postalCode: "551" },
            { area: "集集鎮", postalCode: "552" },
            { area: "水里鄉", postalCode: "553" },
            { area: "魚池鄉", postalCode: "555" },
            { area: "信義鄉", postalCode: "556" },
            { area: "竹山鎮", postalCode: "557" },
            { area: "鹿谷鄉", postalCode: "558" },
        ],
    },
    {
        city: "嘉義縣",
        areas: [
            { area: "番路鄉", postalCode: "602" },
            { area: "梅山鄉", postalCode: "603" },
            { area: "竹崎鄉", postalCode: "604" },
            { area: "阿里山鄉", postalCode: "605" },
            { area: "中埔鄉", postalCode: "606" },
            { area: "大埔鄉", postalCode: "607" },
            { area: "水上鄉", postalCode: "608" },
            { area: "鹿草鄉", postalCode: "611" },
            { area: "太保市", postalCode: "612" },
            { area: "朴子市", postalCode: "613" },
            { area: "東石鄉", postalCode: "614" },
            { area: "六腳鄉", postalCode: "615" },
            { area: "新港鄉", postalCode: "616" },
            { area: "民雄鄉", postalCode: "621" },
            { area: "大林鎮", postalCode: "622" },
            { area: "溪口鄉", postalCode: "623" },
            { area: "義竹鄉", postalCode: "624" },
            { area: "布袋鎮", postalCode: "625" },
        ],
    },
    {
        city: "嘉義市",
        areas: [
            { area: "東區", postalCode: "600" },
            { area: "西區", postalCode: "600" },
        ],
    },
    {
        city: "雲林縣",
        areas: [
            { area: "斗南鎮", postalCode: "630" },
            { area: "大埤鄉", postalCode: "631" },
            { area: "虎尾鎮", postalCode: "632" },
            { area: "土庫鎮", postalCode: "633" },
            { area: "褒忠鄉", postalCode: "634" },
            { area: "東勢鄉", postalCode: "635" },
            { area: "臺西鄉", postalCode: "636" },
            { area: "崙背鄉", postalCode: "637" },
            { area: "麥寮鄉", postalCode: "638" },
            { area: "斗六市", postalCode: "640" },
            { area: "林內鄉", postalCode: "643" },
            { area: "古坑鄉", postalCode: "646" },
            { area: "莿桐鄉", postalCode: "647" },
            { area: "西螺鎮", postalCode: "648" },
            { area: "二崙鄉", postalCode: "649" },
            { area: "北港鎮", postalCode: "651" },
            { area: "水林鄉", postalCode: "652" },
            { area: "口湖鄉", postalCode: "653" },
            { area: "四湖鄉", postalCode: "654" },
            { area: "元長鄉", postalCode: "655" },
        ],
    },
    {
        city: "台南市",
        areas: [
            { area: "中西區", postalCode: "700" },
            { area: "東區", postalCode: "701" },
            { area: "南區", postalCode: "702" },
            { area: "北區", postalCode: "704" },
            { area: "安平區", postalCode: "708" },
            { area: "安南區", postalCode: "709" },
            { area: "永康區", postalCode: "710" },
            { area: "歸仁區", postalCode: "711" },
            { area: "新化區", postalCode: "712" },
            { area: "左鎮區", postalCode: "713" },
            { area: "玉井區", postalCode: "714" },
            { area: "楠西區", postalCode: "715" },
            { area: "南化區", postalCode: "716" },
            { area: "仁德區", postalCode: "717" },
            { area: "關廟區", postalCode: "718" },
            { area: "龍崎區", postalCode: "719" },
            { area: "官田區", postalCode: "720" },
            { area: "麻豆區", postalCode: "721" },
            { area: "佳里區", postalCode: "722" },
            { area: "西港區", postalCode: "723" },
            { area: "七股區", postalCode: "724" },
            { area: "將軍區", postalCode: "725" },
            { area: "學甲區", postalCode: "726" },
            { area: "北門區", postalCode: "727" },
            { area: "新營區", postalCode: "730" },
            { area: "後壁區", postalCode: "731" },
            { area: "白河區", postalCode: "732" },
            { area: "東山區", postalCode: "733" },
            { area: "六甲區", postalCode: "734" },
            { area: "下營區", postalCode: "735" },
            { area: "柳營區", postalCode: "736" },
            { area: "鹽水區", postalCode: "737" },
            { area: "善化區", postalCode: "741" },
            { area: "新市區", postalCode: "744" },
            { area: "大內區", postalCode: "742" },
            { area: "山上區", postalCode: "743" },
            { area: "安定區", postalCode: "745" },
        ],
    },
    {
        city: "澎湖縣",
        areas: [
            { area: "馬公市", postalCode: "880" },
            { area: "西嶼鄉", postalCode: "881" },
            { area: "望安鄉", postalCode: "882" },
            { area: "七美鄉", postalCode: "883" },
            { area: "白沙鄉", postalCode: "884" },
            { area: "湖西鄉", postalCode: "885" },
        ],
    },
    {
        city: "高雄市",
        areas: [
            { area: "新興區", postalCode: "800" },
            { area: "前金區", postalCode: "801" },
            { area: "苓雅區", postalCode: "802" },
            { area: "鹽埕區", postalCode: "803" },
            { area: "鼓山區", postalCode: "804" },
            { area: "旗津區", postalCode: "805" },
            { area: "前鎮區", postalCode: "806" },
            { area: "三民區", postalCode: "807" },
            { area: "楠梓區", postalCode: "811" },
            { area: "小港區", postalCode: "812" },
            { area: "左營區", postalCode: "813" },
            { area: "仁武區", postalCode: "814" },
            { area: "大社區", postalCode: "815" },
            { area: "東沙群島", postalCode: "817" },
            { area: "南沙群島", postalCode: "819" },
            { area: "岡山區", postalCode: "820" },
            { area: "路竹區", postalCode: "821" },
            { area: "阿蓮區", postalCode: "822" },
            { area: "田寮區", postalCode: "823" },
            { area: "燕巢區", postalCode: "824" },
            { area: "橋頭區", postalCode: "825" },
            { area: "梓官區", postalCode: "826" },
            { area: "彌陀區", postalCode: "827" },
            { area: "永安區", postalCode: "828" },
            { area: "湖內區", postalCode: "829" },
            { area: "鳳山區", postalCode: "830" },
            { area: "大寮區", postalCode: "831" },
            { area: "林園區", postalCode: "832" },
            { area: "鳥松區", postalCode: "833" },
            { area: "大樹區", postalCode: "840" },
            { area: "旗山區", postalCode: "842" },
            { area: "美濃區", postalCode: "843" },
            { area: "六龜區", postalCode: "844" },
            { area: "內門區", postalCode: "845" },
            { area: "杉林區", postalCode: "846" },
            { area: "甲仙區", postalCode: "847" },
            { area: "桃源區", postalCode: "848" },
            { area: "那瑪夏區", postalCode: "849" },
            { area: "茂林區", postalCode: "851" },
            { area: "茄萣區", postalCode: "852" },
        ],
    },
    {
        city: "屏東縣",
        areas: [
            { area: "屏東市", postalCode: "900" },
            { area: "三地門鄉", postalCode: "901" },
            { area: "霧臺鄉", postalCode: "902" },
            { area: "瑪家鄉", postalCode: "903" },
            { area: "九如鄉", postalCode: "904" },
            { area: "里港鄉", postalCode: "905" },
            { area: "高樹鄉", postalCode: "906" },
            { area: "鹽埔鄉", postalCode: "907" },
            { area: "長治鄉", postalCode: "908" },
            { area: "麟洛鄉", postalCode: "909" },
            { area: "竹田鄉", postalCode: "911" },
            { area: "內埔鄉", postalCode: "912" },
            { area: "萬丹鄉", postalCode: "913" },
            { area: "潮州鎮", postalCode: "920" },
            { area: "泰武鄉", postalCode: "921" },
            { area: "來義鄉", postalCode: "922" },
            { area: "萬巒鄉", postalCode: "923" },
            { area: "崁頂鄉", postalCode: "924" },
            { area: "新埤鄉", postalCode: "925" },
            { area: "南州鄉", postalCode: "926" },
            { area: "林邊鄉", postalCode: "927" },
            { area: "東港鎮", postalCode: "928" },
            { area: "琉球鄉", postalCode: "929" },
            { area: "佳冬鄉", postalCode: "931" },
            { area: "新園鄉", postalCode: "932" },
            { area: "枋寮鄉", postalCode: "940" },
            { area: "枋山鄉", postalCode: "941" },
            { area: "春日鄉", postalCode: "942" },
            { area: "獅子鄉", postalCode: "943" },
            { area: "車城鄉", postalCode: "944" },
            { area: "牡丹鄉", postalCode: "945" },
            { area: "恆春鎮", postalCode: "946" },
            { area: "滿州鄉", postalCode: "947" },
        ],
    },
    {
        city: "臺東縣",
        areas: [
            { area: "臺東市", postalCode: "950" },
            { area: "綠島鄉", postalCode: "951" },
            { area: "蘭嶼鄉", postalCode: "952" },
            { area: "延平鄉", postalCode: "953" },
            { area: "卑南鄉", postalCode: "954" },
            { area: "鹿野鄉", postalCode: "955" },
            { area: "關山鎮", postalCode: "956" },
            { area: "海端鄉", postalCode: "957" },
            { area: "池上鄉", postalCode: "958" },
            { area: "東河鄉", postalCode: "959" },
            { area: "成功鎮", postalCode: "961" },
            { area: "長濱鄉", postalCode: "962" },
            { area: "太麻里鄉", postalCode: "963" },
            { area: "金峰鄉", postalCode: "964" },
            { area: "大武鄉", postalCode: "965" },
            { area: "達仁鄉", postalCode: "966" },
        ],
    },
    {
        city: "金門縣",
        areas: [
            { area: "金沙鎮", postalCode: "890" },
            { area: "金湖鎮", postalCode: "891" },
            { area: "金寧鄉", postalCode: "892" },
            { area: "金城鎮", postalCode: "893" },
            { area: "烈嶼鄉", postalCode: "894" },
            { area: "烏坵鄉", postalCode: "896" },
        ],
    },
    {
        city: "連江縣",
        areas: [
            { area: "南竿鄉", postalCode: "209" },
            { area: "北竿鄉", postalCode: "210" },
            { area: "莒光鄉", postalCode: "211" },
            { area: "東引鄉", postalCode: "212" },
        ],
    },
];

export const PLAN = {
    PREMIUM: "premium",
    LITE: "lite"
}